import type {NextPage} from 'next';
import Head from 'next/head';
import {Box, Container, Divider, Grid, Typography} from "@mui/material";
import React from "react";
import {Layout} from "../layouts";
import {useSettings} from "../hooks/use-settings";
import {OperationForm} from "../components/operations/operation-form";

const Home: NextPage = () => {
  const settings = useSettings()
  return (
    <>
      <Head>
        <title>
          Mystes
        </title>
      </Head>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth={settings.stretch ? false : 'xl'}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box>
                <Divider />
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={3}
                >
                  <Grid item>
                    <Typography variant="h4">
                      {"Actions rapides"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <OperationForm orientation="horizontal"/>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

Home.getLayout = (page) => (
  <Layout>
    {page}
  </Layout>
);

Home.auth = true

export default Home;
